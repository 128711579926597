// @ts-nocheck
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
  TextField,
  Stack,
  Button,
  Avatar,
  Divider,
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { cloneDeep, isEmpty } from 'lodash';
import { string, object } from 'yup';
import { useCallback, useEffect, useState } from 'react';

import DialogTransition from '../Common/Transition/DialogTransition';
import Multiselect from '../Common/Multiselect';
import MODULE_SUB_MODULE, {
  DYNAMIC_MODULE_SUBMODULE,
} from '../../const/moduleSubmodule';
import { STEP_TITLES } from '../../const/UserManagement';
import REGEX from '../../const/Regex';
import SwalToast from '../Common/swalToast';
import { INVALID_REGEX_MESSAGE } from '../../const/CommonConst';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';
import { getErrorHelperText } from '../../utils/Utils';
import StepButton from './StepButton';
import GroupsPermissionStep from '../Configuration/AdministrativeOptions/GroupsPermissionStep';
import {
  addPermissionKey,
  addTemplateModule,
  extractPermissions,
  iterateNestedObject,
  removeModules,
  transformPermissionJsonObject,
  updateModulePermissions,
} from '../Configuration/AdministrativeOptions/groupPermissionUtils';

const getOptionLabel = (option) => option?.label;
const isOptionEqualToValue = (option, value) => option?.label === value?.label;

const getDisplayPropsForStep = (step, currentStep) => ({
  display: step === currentStep ? 'flex' : 'none',
});

/**
@function CreateEditGroupModal
* @description provide a UI and functionality for creating and updating groups
* @param {bool} open - opens the modal.
* @param {function} handleClose - function to change the open state
* @param {function} getGroupDetails - get and set the list of  all groups 
* @param {function} createGroup - make api call to create a new group 
* @param {function} updateGroup - make api call to update a selected group 
* @param {string} mode - in what mode the modal is open either create or update 
* @param {array} userList - list of all the users
* @param {object} selectedGroup - selected group for update
*/
const CreateEditGroupModal = ({
  open = false,
  handleClose,
  getGroupDetails,
  createGroup,
  updateGroup,
  mode = 'create',
  userList,
  selectedGroup,
  templateList,
  setSkipPageReset,
}) => {
  const [step, setStep] = useState('first');
  const [moduleSubModules, setModuleSubModules] = useState(cloneDeep({}));

  const formValidationSchema = object({
    groupName: string()
      .max(150, 'max characters should be 150')
      .required('Required')
      .matches(REGEX.SPECIAL_CHARACTERS_NOT_ALLOWED, INVALID_REGEX_MESSAGE)
      .matches(REGEX.CANNOT_START_WITH_NUMBERS, INVALID_REGEX_MESSAGE),
    groupDescription: string()
      .max(254, 'max characters should be 254')
      .required('Required')
      .matches(
        REGEX.WHOLE_STRING_CANNOT_BE_SPECIAL_CHARACTERS,
        INVALID_REGEX_MESSAGE
      ),
  });

  const formikForm = useFormik({
    initialValues: {
      groupName: '',
      groupDescription: '',
      userList: [],
      adminList: [],
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      const permissionJson = extractPermissions(moduleSubModules);

      if (process.env.REACT_APP_KEYCLOAK_LOGIN === 'true') {
        delete permissionJson.ModelInventory;
        delete permissionJson.ModelAssociation;
      }
      const body = {
        ...values,
        userList: values?.userList?.map((item) => item?.value),
        adminList: values?.adminList?.map((item) => item?.value),
        permissionJson,
        groupName: values?.groupName?.trim(),
      };
      if (mode === 'create') {
        createGroup(body).then((res) => {
          if (res) {
            getGroupDetails();
            SwalToast({
              icon: 'success',
              title: res?.data?.msg,
            });
            formikForm?.handleReset();
            setStep('first');
            handleClose();
            SwalToast({
              icon: 'warning',
              title:
                'Administrative settings have been changed. These changes require a refresh of the application to take effect.',
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        });
      } else {
        const payload = {
          ...body,
          groupid: selectedGroup?.id,
        };

        updateGroup(payload).then((res) => {
          if (res) {
            getGroupDetails();
            SwalToast({
              icon: 'success',
              title: res?.data?.msg,
            });
            formikForm?.handleReset();
            setStep('first');
            handleClose();
            // turn on the flag to not reset the page
            setSkipPageReset(true);
            SwalToast({
              icon: 'warning',
              title:
                'Administrative settings have been changed. These changes require a refresh of the application to take effect.',
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        });
      }
    },
  });

  const handleCloseModal = useCallback(() => {
    handleClose();
    setStep('first');
    formikForm?.handleReset();
  }, [handleClose, setStep, formikForm?.handleReset]);

  let transformedPermissionsObject = {};

  useEffect(() => {
    switch (mode) {
      case 'create': {
        let initialDefaultPermissionsModules;
        if (process.env.REACT_APP_KEYCLOAK_LOGIN === 'true') {
          initialDefaultPermissionsModules = addPermissionKey(
            cloneDeep(DYNAMIC_MODULE_SUBMODULE)
          );
        } else {
          initialDefaultPermissionsModules = addPermissionKey(
            cloneDeep(removeModules(MODULE_SUB_MODULE, ['AR']))
          );
        }
        const updatedModuleSubModule = addTemplateModule(
          initialDefaultPermissionsModules,
          templateList
        );
        setModuleSubModules(cloneDeep(updatedModuleSubModule));
        break;
      }
      case 'edit': {
        let initialDefaultPermissionsModulesEdit;
        if (process.env.REACT_APP_KEYCLOAK_LOGIN === 'true') {
          initialDefaultPermissionsModulesEdit = addPermissionKey(
            cloneDeep(DYNAMIC_MODULE_SUBMODULE)
          );
        } else {
          initialDefaultPermissionsModulesEdit = addPermissionKey(
            cloneDeep(removeModules(MODULE_SUB_MODULE, ['AR']))
          );
        }
        const updatedModuleSubModuleEdit = addTemplateModule(
          initialDefaultPermissionsModulesEdit,
          templateList
        );

        if (selectedGroup) {
          formikForm?.setValues({
            groupName: selectedGroup?.groupName,
            groupDescription: selectedGroup?.description,
            userList: selectedGroup?.users?.map((item) => ({
              label: item?.username,
              value: item?.userId,
            })),
            adminList: selectedGroup?.admin_users?.map((item) => ({
              label: item?.username,
              value: item?.userId,
            })),
          });

          if (selectedGroup?.permissionJson) {
            // Transforming the permission object received from backend.
            transformedPermissionsObject = transformPermissionJsonObject(
              selectedGroup?.permissionJson
            );

            // Iterate through every nested module and subModules and updating the permission according to backend permission json
            iterateNestedObject(
              updatedModuleSubModuleEdit,
              transformedPermissionsObject
            );

            let result = {};
            // Iterate through every nested module and subModules from bottom to top and updating the all modules permissions according to newly added objects (i.e templates or modules)
            result = updateModulePermissions(
              cloneDeep(updatedModuleSubModuleEdit)
            );

            setModuleSubModules({ ...cloneDeep(result) });
          }
        }
        break;
      }
      default:
        break;
    }
  }, [mode, selectedGroup, templateList]);

  /**
   * Handles the click event for a specific action.
   * Validates the form and updates the step or submits the form accordingly.
   * @function
   * @name handleClick
   * @returns {void}
   */
  const handleClick = useCallback(() => {
    formikForm?.validateForm().then((validate) => {
      if (
        formikForm?.values?.groupDescription?.length <= 254 &&
        formikForm?.values?.groupName?.length <= 150 &&
        Object.keys(validate).length === 0
      ) {
        setStep('second');
      } else {
        formikForm?.handleSubmit();
      }
    });
  }, [
    formikForm?.validateForm,
    formikForm?.values,
    formikForm?.handleSubmit,
    setStep,
  ]);

  //  Removing the admin if we remove it from users list
  useEffect(() => {
    const filteredAdminList = formikForm?.values?.adminList?.filter(
      (adminUser) =>
        formikForm?.values?.userList?.find(
          (user) => user?.value === adminUser?.value
        )
    );
    // Update the adminList only if it has changed
    if (filteredAdminList?.length !== formikForm?.values?.adminList?.length) {
      formikForm?.setFieldValue('adminList', filteredAdminList);
    }
  }, [formikForm?.values?.userList]);

  const renderUserListInput = useCallback(
    (params) => (
      <TextField
        {...params}
        label="Users"
        placeholder={formikForm?.values?.userList.length ? '' : 'Select users.'}
        helperText={
          formikForm?.touched?.userList && formikForm?.errors?.userList
            ? formikForm?.errors?.userList
            : null
        }
        error={
          formikForm?.touched?.userList && Boolean(formikForm?.errors?.userList)
        }
      />
    ),
    [
      formikForm?.values?.userList.length,
      formikForm.touched?.userList,
      formikForm.errors?.userList,
    ]
  );
  const handleUserListChange = useCallback(
    (value) => {
      formikForm?.setFieldValue('userList', value);
    },
    [formikForm?.setFieldValue]
  );

  const renderAdminListInput = useCallback(
    (params) => (
      <TextField
        {...params}
        label="Admins"
        placeholder={
          formikForm?.values?.adminList.length ? '' : 'Select admins.'
        }
        helperText={
          formikForm?.touched?.adminList && formikForm?.errors?.adminList
            ? formikForm?.errors?.adminList
            : null
        }
        error={
          formikForm?.touched?.adminList &&
          Boolean(formikForm?.errors?.adminList)
        }
      />
    ),
    [
      formikForm?.values?.adminList.length,
      formikForm?.touched?.adminList,
      formikForm?.errors?.adminList,
    ]
  );
  const handleAdminListChange = useCallback(
    (value) => {
      formikForm?.setFieldValue('adminList', value);
    },
    [formikForm?.setFieldValue]
  );

  return (
    <Box>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={open}
        onClose={handleCloseModal}
        TransitionComponent={DialogTransition}
        sx={{
          '& .MuiPaper-root.MuiDialog-paper': {
            'padding-bottom': '42px',
          },
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h3">{STEP_TITLES[mode][step]}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={5}
          xs={12}
          {...getDisplayPropsForStep('first', step)}
        >
          <Grid
            item
            container
            xs={12}
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={6}>
              <TextField
                required
                name="groupName"
                value={formikForm?.values?.groupName}
                {...formikForm?.getFieldProps('groupName')}
                placeholder="Enter group name."
                label="Group name"
                helperText={getErrorHelperText(
                  formikForm?.errors?.groupName,
                  formikForm?.touched?.groupName
                )}
                error={Boolean(
                  getErrorHelperText(
                    formikForm?.errors?.groupName,
                    formikForm?.touched?.groupName
                  )
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                name="groupDescription"
                value={formikForm?.values?.groupDescription}
                {...formikForm?.getFieldProps('groupDescription')}
                placeholder="Enter group description."
                label="Group description"
                helperText={getErrorHelperText(
                  formikForm?.errors?.groupDescription,
                  formikForm?.touched?.groupDescription
                )}
                error={Boolean(
                  getErrorHelperText(
                    formikForm?.errors?.groupDescription,
                    formikForm?.touched?.groupDescription
                  )
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Multiselect
                name="userList"
                options={
                  userList?.length > 0
                    ? userList?.map((user) => {
                        return { label: user?.username, value: user?.user_id };
                      })
                    : []
                }
                value={
                  isEmpty(formikForm?.values?.userList)
                    ? []
                    : formikForm?.values?.userList
                }
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                noOptionsMessage="No user found."
                popupIcon={<DropdownArrow />}
                renderInput={renderUserListInput}
                onChange={handleUserListChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Multiselect
                name="adminList"
                options={
                  formikForm?.values?.userList?.length > 0
                    ? formikForm?.values?.userList
                    : []
                }
                value={
                  isEmpty(formikForm?.values?.adminList)
                    ? []
                    : formikForm?.values?.adminList
                }
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                noOptionsMessage="No user found."
                popupIcon={<DropdownArrow />}
                renderInput={renderAdminListInput}
                onChange={handleAdminListChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={3} justifyContent="center" mt={3}>
                <Button onClick={formikForm?.handleReset}>RESET</Button>
                <Button onClick={handleClick} variant="contained">
                  NEXT
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        {/* Second Step */}
        <Grid container {...getDisplayPropsForStep('second', step)}>
          <Grid xs={12} item textAlign="center">
            <Box mb={2}>
              <Typography textAlign="center">
                Please select permissions.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <GroupsPermissionStep
              moduleSubModules={moduleSubModules}
              setModuleSubModules={setModuleSubModules}
            />
          </Grid>
          <Stack
            width="100%"
            direction="row"
            spacing={3}
            justifyContent="center"
            mt={3}
          >
            <StepButton label="BACK" step="first" setStep={setStep} />
            <StepButton
              color="primary"
              variant="contained"
              label="PREVIEW SCREEN"
              step="third"
              setStep={setStep}
            />
          </Stack>
        </Grid>

        <Grid container {...getDisplayPropsForStep('third', step)}>
          <Grid item xs={12}>
            <Box pl={2} overflow="hidden" textOverflow="ellipsis">
              <Typography variant="body2" color="other.grey2">
                Group name:&nbsp;
              </Typography>
              <Typography variant="body1" sx={{ lineHeight: '19.07px' }}>
                {formikForm?.values?.groupName
                  ? formikForm?.values?.groupName
                  : 'Please add group name'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} overflow="hidden" textOverflow="ellipsis">
              <Typography variant="body2" color="other.grey2">
                Description:&nbsp;
              </Typography>
              <Typography variant="body2">
                {formikForm?.values?.groupDescription
                  ? formikForm?.values?.groupDescription
                  : 'Please add group description'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2}>
              <Grid container spacing={2}>
                {formikForm?.values?.userList?.length > 0 ? (
                  formikForm?.values?.userList?.slice(0, 23).map((item) => (
                    <Grid key={item?.value} item xs={1.5} title={item?.label}>
                      <Avatar
                        sx={{
                          color: 'text.main',
                          backgroundColor: 'primary.main',
                        }}
                      >
                        {item?.label
                          .match(/(\b\S)?/g)
                          .join('')
                          .toUpperCase()}
                      </Avatar>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body2" ml="15px">
                    No users added
                  </Typography>
                )}
                {formikForm?.values?.userList?.length > 23 && (
                  <Grid item xs={1.5}>
                    <Avatar
                      sx={{
                        color: 'primary.main',
                        backgroundColor: 'secondary.main',
                      }}
                    >
                      {
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        `${formikForm?.values?.userList?.length - 23}`
                      }
                    </Avatar>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Divider
            variant="fullWidth"
            sx={{
              borderColor: 'secondary.main',
            }}
          />
          <Grid item xs={12}>
            <Box p={2}>
              <Grid container spacing={2}>
                {Object.values(moduleSubModules).map((module) => (
                  <Grid item xs={12} container key={module?.key}>
                    <Grid item xs={5}>
                      <Typography variant="body2">{module?.label}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body3">
                        {module?.permission === 'View'
                          ? module?.permission
                          : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body3">
                        {module?.permission === 'Manage'
                          ? module?.permission
                          : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body3">
                        {module?.permission === 'None'
                          ? module?.permission
                          : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body3">
                        {module?.permission === 'Custom'
                          ? module?.permission
                          : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
              <StepButton label="BACK" step="second" setStep={setStep} />
              <Button
                color="primary"
                variant="contained"
                onClick={formikForm?.handleSubmit}
              >
                {mode === 'create' ? 'CREATE GROUP' : 'UPDATE GROUP'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

CreateEditGroupModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  getGroupDetails: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  setSkipPageReset: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  selectedGroup: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
    .isRequired,
  userList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  templateList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
};
export default CreateEditGroupModal;
