// @ts-nocheck
// @ts-ignore
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Route,
  Routes,
  Navigate,
  // Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Box, IconButton, styled, CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarProvider as Snackbar } from 'notistack';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { loginSuccess } from './store/slices/UserSilcer';
import { isValueEmpty, initKeycloak } from './utils/Utils';
import Organization from './views/Login/Organization';
import useCurrentTheme from './Theme/useCurrentTheme';
import { initializeAxiosForKeycloak } from './utils/AxiosKeyCloak';
import NotAuthorized from './components/Common/NotAuthorized';
import NotFound404 from './components/Common/NotFound404';
import FullscreenLoader from './components/Common/FullScreenLoader';
import { initializeAxios } from './utils/Axios';
import { Routes as routes } from './Routes';
import './App.css';
import Layout from './views/Layout/Layout';
import Login from './views/Login/Login';

const HelpAndSupport = React.lazy(
  () => import('./views/HelpAndSupport/HelpAndSupport')
);

const CloseSnackbarIconButton = ({ key, onClickSnackBarClose }) => {
  const onClick = useCallback(
    () => onClickSnackBarClose(key),
    [key, onClickSnackBarClose]
  );
  return (
    <IconButton onClick={onClick}>
      <CloseIcon
        sx={{
          color: (theme) => theme?.palette?.other?.white,
        }}
      />
    </IconButton>
  );
};

CloseSnackbarIconButton.propTypes = {
  onClickSnackBarClose: PropTypes.func.isRequired,
  key: PropTypes.string.isRequired,
};

if (
  process.env.REACT_APP_KEYCLOAK_LOGIN === 'true' &&
  !isValueEmpty(localStorage.getItem('REALM_ID'))
) {
  if (isValueEmpty(localStorage.getItem('access_token'))) {
    initKeycloak().then(() => {
      initializeAxiosForKeycloak();
    });
  } else {
    initKeycloak();
    initializeAxiosForKeycloak();
  }
} else {
  initializeAxios();
}

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ children, isLoggedIn, loading }) => {
  const location = useLocation();

  if (!isLoggedIn) {
    if (
      process.env.REACT_APP_KEYCLOAK_LOGIN === 'true' &&
      !isValueEmpty(localStorage.getItem('REALM_ID')) &&
      loading
    ) {
      return <h5>Loading...</h5>;
    }
    if (
      process.env.REACT_APP_KEYCLOAK_LOGIN === 'true' &&
      isValueEmpty(localStorage.getItem('REALM_ID'))
    ) {
      return <Navigate to="/org" state={{ from: location }} replace />;
    }
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};
const SnackbarProvider = styled(Snackbar)`
  &.SnackbarItem-contentRoot {
    width: 345px;
  }
  & .SnackbarItem-message {
    margin-right: 27px;
    word-break: break-word;
  }
  & .SnackbarItem-action {
    position: absolute;
    right: 0;
    top: 5px;
    margin-right: 5px;
    padding: 0;
  }
  &.SnackbarItem-variantSuccess {
    background: ${(p) => p.theme.palette.success.main};
  }
  &.SnackbarItem-variantError {
    background: ${(p) => p.theme.palette.error.main};
  }
  &.SnackbarItem-variantWarning {
    background: ${(p) => p.theme.palette.warning.main};
  }
  &.SnackbarItem-variantInfo {
    background: ${(p) => p.theme.palette.info.main};
  }
`;

function App() {
  const { isAuthenticate } = useSelector((state) => state.users);
  const theme = useCurrentTheme('light-theme');
  const notistackRef = useRef();
  const { users } = useSelector((state) => state);
  const { permissions } = users;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // @ts-ignore
    window.enqueueSnackbar = notistackRef.current.enqueueSnackbar;
    window.closeSnackbar = notistackRef.current.closeSnackbar;
  }, []);

  /**
   * @function  onClickSnackBarClose
   * @description when click on cross button of toast dialog it will close the snack bar
   */
  const onClickSnackBarClose = useCallback((key) => {
    notistackRef.current.closeSnackbar(key);
  }, []);

  const ChildRoutes = React.useMemo(() => {
    // Generates child routes based on provided route configurations and permissions.
    // Returns an array of React elements representing the child routes.
    return routes?.map(({ path, element, permission }) => {
      // Extracts required permissions for the current route.
      const requiredPermissions = permissions?.[permission];

      // Checks if the required permissions for the current route are 'None'.
      if (requiredPermissions === 'None') {
        // If permissions are 'None', renders a route with a special Permissions component.
        return <Route key={path} path={path} element={<NotAuthorized />} />;
      }

      // If permissions are not 'None', renders a route with the original element.
      return <Route key={path} path={path} element={element} />;
    });
  }, [permissions, routes]);

  const [loading, setLoading] = useState(true);

  /**
   * Handles the Keycloak authentication event.
   *
   * @callback handleKeycloakEvent
   * @param {Object} event - The event object containing Keycloak authentication details.
   */
  const handleKeycloakEvent = React.useCallback(
    (event) => {
      const { auth, keycloakConfig } = event.detail;
      const keycloakUserData = keycloakConfig.tokenParsed;
      const userData = {
        username: keycloakUserData.preferred_username,
        email: keycloakUserData.email,
        id: keycloakUserData.sub,
      };

      window.KEYCLOAK_CONFIG = keycloakConfig;
      dispatch(
        loginSuccess({
          isAuthenticate: auth,
          token: keycloakConfig.token,
          refresh: keycloakConfig.refreshToken,
          user: userData,
        })
      );
      setLoading(false);
      if (location?.pathname === '/org') {
        navigate('/dashboard?value=0');
      }
    },
    [dispatch, loginSuccess, setLoading]
  );

  const renderActionComponent = useCallback(
    (key) => (
      <CloseSnackbarIconButton
        key={key}
        onClickSnackBarClose={onClickSnackBarClose}
      />
    ),
    [onClickSnackBarClose]
  );

  useEffect(() => {
    document.addEventListener('KEYCLOAK_LOGIN_SUCCESS', handleKeycloakEvent);
    if (process.env.REACT_APP_KEYCLOAK_LOGIN !== 'true') {
      return () => {
        document.removeEventListener(
          'KEYCLOAK_LOGIN_SUCCESS',
          handleKeycloakEvent
        );
      };
    }
    return () => {};
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          hideIconVariant
          preventDuplicate
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={5000}
          // eslint-disable-next-line react/no-unstable-nested-components
          action={renderActionComponent}
        >
          <FullscreenLoader />

          <React.Suspense
            fallback={
              <Box
                display="flex"
                flexGrow={1}
                width="100%"
                height="100vh"
                alignItems="center"
                flexDirection="row"
              >
                <Box
                  alignItems="center"
                  display="flex"
                  marginRight="auto"
                  marginLeft="auto"
                >
                  <CircularProgress />
                </Box>
              </Box>
            }
          >
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/org" element={<Organization />} />
              <Route
                path="/"
                element={
                  <PrivateRoute
                    loading={loading}
                    isLoggedIn={localStorage.getItem('auth') || isAuthenticate}
                  >
                    <Layout />
                  </PrivateRoute>
                }
              >
                {ChildRoutes}
              </Route>
              <Route
                path="/help"
                element={
                  <PrivateRoute
                    loading={loading}
                    isLoggedIn={localStorage.getItem('auth') || isAuthenticate}
                  >
                    <HelpAndSupport />
                  </PrivateRoute>
                }
              />
              <Route path="*" name="Not Found" element={<NotFound404 />} />
            </Routes>
          </React.Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
