// @ts-nocheck
import React from 'react';
import { TextField, Button, Grid, Box, Typography, Stack } from '@mui/material';
import Keycloak from 'keycloak-js';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import { initializeAxiosForKeycloak } from '../../utils/AxiosKeyCloak';
import { ReactComponent as NimbusLogo } from '../../assets/Icons/MRMVaultColored.svg';
import LoginCarousel from './LoginCarousel';
import { initKeycloak, getErrorHelperText } from '../../utils/Utils';

const Organization = () => {
  const formForgetValidationSchema = object({
    realmId: string().required('Required'),
  });
  const organizationForm = useFormik({
    initialValues: {
      realmId: '',
    },
    enableReinitialize: true,
    validationSchema: formForgetValidationSchema,
    onSubmit: (values) => {
      localStorage.setItem('REALM_ID', values?.realmId);

      const initOptions = {
        url: process.env.REACT_APP_KEYCLOACK_URL,
        realm: localStorage.getItem('REALM_ID'),
        clientId: 'Vault',
        onLoad: 'check-sso', // check-sso | login-required
        KeycloakResponseType: 'code',
      };
      const keycloakConfig = new Keycloak(initOptions);
      window.KEYCLOAK_CONFIG = keycloakConfig;
      initKeycloak().then(() => {
        initializeAxiosForKeycloak();
      });
    },
  });

  return (
    <Grid
      container
      sx={{
        position: 'absolute',
        width: '100%',
        minWidth: '400px',
        minHeight: '100vh',
        overflow: 'auto',
      }}
    >
      <Grid container item xs={12} md={6.5} px={3} pt={3}>
        <Box position="fixed" p={1}>
          <NimbusLogo height="4rem" width="7rem" />
        </Box>
        <LoginCarousel />
      </Grid>
      <Grid
        item
        xs={12}
        md={5.5}
        container
        px={10}
        sx={{ background: (theme) => theme.palette.primary.dark }}
        p={3}
      >
        <Grid
          item
          xs={6}
          flexDirection="column"
          alignItems="center"
          mx="auto"
          my="auto"
        >
          <Stack direction="column" spacing={3}>
            <Box>
              <TextField
                required
                {...organizationForm?.getFieldProps('realmId')}
                variant="filled"
                label="Enter account Id"
                helperText={getErrorHelperText(
                  organizationForm?.errors?.realmId,
                  organizationForm?.touched?.realmId
                )}
                error={Boolean(
                  getErrorHelperText(
                    organizationForm?.errors?.realmId,
                    organizationForm?.touched?.realmId
                  )
                )}
              />
            </Box>
            <Stack justifyContent="center" alignItems="center">
              <Box
                display="flex"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={organizationForm.handleSubmit}
                  sx={{
                    background: (theme) => theme.palette.primary.contrastText,
                    width: '200px', // Increase the width
                    height: '35px', // Reduce the height
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.other.white,
                    },
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2) !important',
                    borderRadius: '5px',
                  }}
                >
                  <Typography
                    letterSpacing={3}
                    color="primary.dark"
                    variant="h4"
                  >
                    Submit
                  </Typography>
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Organization;
