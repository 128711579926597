// @ts-nocheck
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  Stack,
  Typography,
  IconButton,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  Badge,
} from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FaSignOutAlt } from 'react-icons/fa';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as NimbusLogo } from '../../assets/Icons/MRMvault.svg';
import NotificationList from './Notification/NotificationList';
import useCommon from '../../hooks/useCommon';
import ConfirmModal from '../Common/Modals/ConfirmModal';
import useAuth from '../../hooks/Auth/useAuth';
import SwalToast from '../Common/swalToast';
import ArticleSearchModal from '../HelpAndSupport/modals/ArticleSearchModal';
import useModalDisclosure from '../../hooks/useModalDisclosure';
import { getTagsFromRoute } from '../../utils/Utils';

const AppHeader = ({ getNotification, notificationList }) => {
  const { common, dispatch, setIsWorkflowCompiled } = useCommon();
  const { logout } = useAuth();
  const { open: helpModalOpen, toggleOpen: toggleHelpModal } =
    useModalDisclosure();

  const history = useNavigate();
  const location = useLocation();
  const helpTags = getTagsFromRoute(location.pathname);

  const logOut = () => {
    const refreshToken = localStorage.getItem('refresh_token');
    const accessToken = localStorage.getItem('access_token');
    const userDetails = localStorage.getItem('user_data');
    const username = userDetails ? JSON.parse(userDetails)?.username : null;

    logout({
      refresh_token: refreshToken,
      access_token: accessToken,
      username,
    }).then((res) => {
      if (res) {
        SwalToast({
          icon: 'success',
          title: 'User logged out successfully.',
        });
      }
    });
  };

  const logOutKeyClock = () => {
    const keyToKeep = 'REALM_ID';
    const valueToKeep = localStorage.getItem(keyToKeep);
    localStorage.clear();
    sessionStorage.clear();
    if (valueToKeep !== null) {
      localStorage.setItem(keyToKeep, valueToKeep);
    }
    window.KEYCLOAK_CONFIG.logout();
  };

  const handleLogOut = useCallback(() => {
    if (process.env.REACT_APP_KEYCLOAK_LOGIN === 'true') {
      logOutKeyClock();
    } else {
      logOut();
    }
  }, [logOutKeyClock, logOut]);

  const { userData } = useSelector((state) => state.users);
  const [open, setOpen] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [notificationsData, setNotificationsData] = useState([]);
  const [navigateToRoute, setNavigateToRoute] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const anchorRef = useRef();

  /**
   * If workflow is not compile show modal for confirmation
   *  @name handleModalOpen
   * @returns {void}
   */
  const handleModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  /**
   * Closes the confirmation modal by setting the state value.
   * @function
   * @name closeConfirmModal
   * @returns {void}
   * @example closeConfirmModal()
   */
  const closeConfirmModal = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, [setIsConfirmModalOpen]);

  /**
   * Confirms the action, if user have compiled the workflow or not.
   *  @name confirm
   * @returns {void}
   */
  const confirm = useCallback(() => {
    dispatch(setIsWorkflowCompiled(true));
    closeConfirmModal();
    setTimeout(() => {
      history(navigateToRoute);
    }, 0);
  }, [dispatch, setIsWorkflowCompiled, navigateToRoute]);

  /**
   * @function handleToggle
   * @description Reversing state of prevOpen
   * @returns {void}
   * @example handleToggle()
   */
  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const handleCloseNotifications = useCallback(() => {
    setOpen(false);
    setNotificationsData([]);
  }, [setOpen, setNotificationsData]);

  const calculateUnreadNotifications = useCallback(
    (olderNotifications = [], todaysNotifications = []) => {
      if (notificationsData?.length > 0) {
        const unreadOldNotifications = olderNotifications?.filter(
          (notification) => !notification?.read_status
        )?.length;
        const unreadTodaysNotifications = todaysNotifications?.filter(
          (notification) => !notification?.read_status
        )?.length;
        setUnreadNotificationsCount(
          unreadOldNotifications + unreadTodaysNotifications
        );
      }
    },
    [setUnreadNotificationsCount]
  );

  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    // checking unread messages count and initializing notificationsData with notificationList
    const count = notificationList?.filter(
      (notification) => !notification?.read_status
    )?.length;

    setUnreadNotificationsCount(count);

    setNotificationsData(notificationList);
  }, [notificationList]);

  const redirectToTask = useCallback(() => {
    setNavigateToRoute({
      pathname: '/dashboard',
      search: `value=${5}`,
    });
    // If we are trying to navigate from edit workflow route to any other route then we will check if workflow is compiled or not, if it is compiled then only we can navigate to other routes
    if (common?.isWorkflowCompiled) {
      history({
        pathname: '/dashboard',
        search: `value=${5}`,
      });
    } else {
      handleModalOpen();
    }
  }, [setNavigateToRoute, handleModalOpen, history]);

  return (
    <Box>
      <AppBar elevation={0} position="static">
        <Toolbar
          sx={{
            padding: '0px 10px 0px 12px !important',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box display="flex" alignItems="center">
              <NimbusLogo height="2rem" width="4.5rem" />
            </Box>
            <Stack spacing={2} direction="row" mt={1}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1" color="light">
                  {userData?.username}
                </Typography>
                {/* <Typography variant="body3" color="light">
                  {profileData?.role}
                </Typography> */}
              </Box>
              <IconButton
                color="inherit"
                title="Help & support"
                aria-label="Help & support"
                onClick={toggleHelpModal}
                sx={{
                  '&:focus-visible': {
                    outline: (theme) =>
                      `2px solid ${theme?.palette?.secondary?.contrastText}`,
                    background: (theme) => theme?.palette?.primary?.main,
                  },
                }}
              >
                <HelpOutline
                  sx={{ color: (theme) => theme?.palette?.other?.white }}
                />
              </IconButton>
              <IconButton
                color="inherit"
                title="Tasks"
                aria-label="Tasks"
                sx={{
                  '&:focus-visible': {
                    outline: (theme) =>
                      `2px solid ${theme?.palette?.secondary?.contrastText}`,
                    background: (theme) => theme?.palette?.primary?.main,
                  },
                }}
                onClick={redirectToTask}
              >
                <AssignmentIcon />
              </IconButton>
              <Badge
                badgeContent={unreadNotificationsCount}
                sx={{
                  '& .MuiBadge-badge': {
                    color: (theme) => theme?.palette?.other?.white,
                    backgroundColor: (theme) =>
                      theme?.palette?.other?.badgeBorder,
                    marginRight: '10px',
                    marginTop: '10px',
                  },
                }}
              >
                <IconButton
                  onClick={handleToggle}
                  color="inherit"
                  title="Notifications"
                  aria-label="Notifications"
                  sx={{
                    '&:focus-visible': {
                      outline: (theme) =>
                        `2px solid ${theme?.palette?.secondary?.contrastText}`,
                      background: (theme) => theme?.palette?.primary?.main,
                    },
                  }}
                  ref={anchorRef}
                >
                  <NotificationsIcon />
                </IconButton>
              </Badge>

              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                sx={{ zIndex: 10 }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                      marginTop: '1px',
                      marginRight: '24px',
                    }}
                  >
                    <Paper
                      backgroundColor="primary"
                      sx={{
                        height: 'calc(100vh - 58px)',
                        width: '430px',
                        overflow: 'hidden',
                      }}
                    >
                      <ClickAwayListener onClickAway={handleCloseNotifications}>
                        <Box p={2}>
                          <NotificationList
                            handleCloseNotifications={handleCloseNotifications}
                            getNotification={getNotification}
                            calculateUnreadNotifications={
                              calculateUnreadNotifications
                            }
                            notificationsData={notificationsData}
                            setNotificationsData={setNotificationsData}
                            setUnreadNotificationsCount={
                              setUnreadNotificationsCount
                            }
                            notificationList={notificationList}
                          />
                        </Box>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>

              <IconButton
                title="Logout"
                aria-label="Logout"
                sx={{
                  '&:focus-visible': {
                    outline: (theme) =>
                      `2px solid ${theme?.palette?.secondary?.contrastText}`,
                    background: (theme) => theme?.palette?.primary?.main,
                  },
                }}
                onClick={handleLogOut}
                color="inherit"
              >
                <FaSignOutAlt color="light" />
              </IconButton>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleClose={closeConfirmModal}
        confirm={confirm}
        alertLabelText={
          <Typography>{`You have unsaved changes. Do you really want to leave this page
        without saving?`}</Typography>
        }
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
      />
      <ArticleSearchModal
        tags={helpTags}
        open={helpModalOpen}
        onClose={toggleHelpModal}
        openLinksInNewTab
      />
    </Box>
  );
};
AppHeader.propTypes = {
  getNotification: PropTypes.func.isRequired,
  clearAllNotification: PropTypes.func.isRequired,
  notificationList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
};

export default AppHeader;
